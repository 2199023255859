import React, { useState, useEffect } from "react";
import { PriceSelectPill } from "../atoms/PriceSelectPill";
import { SquareButton } from "../atoms";
import { ContactModal } from "../professionals";
import logo from "../../static/img/INFO_GRID_WHITE_LOGO.png";

export default ({
  onSubscribe,
  subscriptions,
  activeSubscription,
  canSuscribe,
  mail,
}) => {
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [selectedPeriod, setSelectedPeriod] = useState("monthly");
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const selectedSubscription = subscriptions.find(
      (s) =>
        s.subscriptionType.type === "professional" && s.type === selectedPeriod
    );
    setSelectedSubscription(selectedSubscription);
  }, [subscriptions, selectedPeriod]);

  return (
    <div className={"plan_card_container"}>
      {selectedSubscription && (
        <div className={"plan_card default_shadow background_gray"}>
          <div className={"bg_blue infogrid_card_header"}>
            <img src={logo} alt="logo" />
          </div>
          <div className="body p-4 d-flex flex-column align-items-center justify-content-start">
            <h4 className={"font-weight-bold color_blue"}>Grupal</h4>
            <p className={"text-center"}>
              ¿Estás buscando un plan más grande o una solución a medida?
            </p>
            <p className="text-center font-weight-bold">
              Contactá a nuestro equipo comercial
            </p>
            <div
              className={"border-bottom w-100 d-flex justify-content-center"}
            >
              <div className="w-75">
                <SquareButton
                  pro={true}
                  onClick={() => setShowModal(true)}
                  text={"Contactanos"}
                  className={"mb-3"}
                />
              </div>
              <ContactModal
                show={showModal}
                hide={() => setShowModal(false)}
                mail={mail}
                consulta={true}
              />
            </div>
            <div
              className={
                "d-flex border-bottom w-100 py-4 flex-column align-items-center justify-content-center"
              }
            >
              <p className={"font-weight-bold"}>Incluye</p>
              <p className="mb-1">Autos & Motos</p>
              <p className="mb-1">Visualización en formato Grilla</p>
              <p className="mb-1 text-center">
                Plan de consultas a medida por Usuario
              </p>
              <p className="mb-1">Función Búsquedas Masivas</p>
            </div>
            <div
              className={
                "d-flex w-100 py-4 flex-column align-items-center justify-content-center"
              }
            >
              <p className={"font-weight-bold"}>Características</p>
              <p className="mb-1">Ficha Técnica Ilimitada</p>
              <p className="mb-1">Descripción Marca / Modelo / Versión</p>
              <p className="mb-1">Visualización de CODIA</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
