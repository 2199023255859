import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { ApiHelper } from "../../helpers";
import { Select, SquareButton } from "../atoms";

const barrios = [
  {
    name: "Chacarita",
    comuna: 15,
    perimetro: 7724.8529545700003,
    area: 3115707.1062699999,
  },
  {
    name: "Paternal",
    comuna: 15,
    perimetro: 7087.5132953299999,
    area: 2229829.03363,
  },
  {
    name: "Villa Crespo",
    comuna: 15,
    perimetro: 8131.8570753200001,
    area: 3615977.5563400001,
  },
  {
    name: "Almagro",
    comuna: 5,
    perimetro: 8537.9013684900001,
    area: 4050752.2452400001,
  },
  {
    name: "Caballito",
    comuna: 6,
    perimetro: 10990.964471499999,
    area: 6851028.9135699989,
  },
  {
    name: "Villa Santa Rita",
    comuna: 11,
    perimetro: 5757.7523347099996,
    area: 2153690.81329,
  },
  {
    name: "Monte Castro",
    comuna: 10,
    perimetro: 7361.0364175499999,
    area: 2627481.3250799999,
  },
  {
    name: "Villa Real",
    comuna: 10,
    perimetro: 5070.9656524399998,
    area: 1334146.08283,
  },
  {
    name: "Flores",
    comuna: 7,
    perimetro: 15772.439509600001,
    area: 8590784.1312899999,
  },
  {
    name: "Floresta",
    comuna: 10,
    perimetro: 7519.6540918000001,
    area: 2320640.73551,
  },
  {
    name: "Constitución",
    comuna: 1,
    perimetro: 6326.8413747300001,
    area: 2101994.9981300002,
  },
  {
    name: "San Cristobal",
    comuna: 3,
    perimetro: 5987.2264787900003,
    area: 2043711.1216599999,
  },
  {
    name: "Boedo",
    comuna: 5,
    perimetro: 6602.5102659300001,
    area: 2609850.8792099999,
  },
  {
    name: "Velez Sarsfield",
    comuna: 10,
    perimetro: 7045.5783317200003,
    area: 2400518.32602,
  },
  {
    name: "Villa Luro",
    comuna: 10,
    perimetro: 8652.9300312699997,
    area: 2566936.8725100001,
  },
  {
    name: "Parque Patricios",
    comuna: 4,
    perimetro: 7878.4349931099996,
    area: 3743439.99645,
  },
  {
    name: "Mataderos",
    comuna: 9,
    perimetro: 11124.6357921,
    area: 7398178.8403500002,
  },
  {
    name: "Villa Lugano",
    comuna: 8,
    perimetro: 15057.468389400001,
    area: 9296142.3614000008,
  },
  {
    name: "San Telmo",
    comuna: 1,
    perimetro: 5178.0328392800002,
    area: 1232270.1587400001,
  },
  {
    name: "Saavedra",
    comuna: 12,
    perimetro: 11132.139345600001,
    area: 5538676.8328200001,
  },
  {
    name: "Coghlan",
    comuna: 12,
    perimetro: 4627.5631313800004,
    area: 1279951.37256,
  },
  {
    name: "Villa Urquiza",
    comuna: 12,
    perimetro: 10021.459664800001,
    area: 5449058.9228499997,
  },
  {
    name: "Colegiales",
    comuna: 13,
    perimetro: 6374.6190450699996,
    area: 2289774.1042800001,
  },
  {
    name: "Balvanera",
    comuna: 3,
    perimetro: 8375.8218108100009,
    area: 4342280.2680400005,
  },
  {
    name: "Villa Gral. Mitre",
    comuna: 11,
    perimetro: 6290.4405605600004,
    area: 2162275.1153500001,
  },
  {
    name: "Parque Chas",
    comuna: 15,
    perimetro: 4531.2533962799998,
    area: 1385411.5137400001,
  },
  {
    name: "Agronomía",
    comuna: 15,
    perimetro: 6556.1677718700003,
    area: 2122169.3421200002,
  },
  {
    name: "Villa Ortuzar",
    comuna: 15,
    perimetro: 5684.9952111399998,
    area: 1853802.0520299999,
  },
  {
    name: "Barracas",
    comuna: 4,
    perimetro: 12789.7917712,
    area: 7953453.4314299999,
  },
  {
    name: "Parque Avellaneda",
    comuna: 9,
    perimetro: 10165.614027699999,
    area: 4732708.2093099998,
  },
  {
    name: "Parque Chacabuco",
    comuna: 7,
    perimetro: 8406.9143207500001,
    area: 3832116.6992199998,
  },
  {
    name: "Nueva Pompeya",
    comuna: 4,
    perimetro: 11033.5103019,
    area: 4951510.8742699996,
  },
  {
    name: "Palermo",
    comuna: 14,
    perimetro: 22126.531858400002,
    area: 15845871.288000001,
  },
  {
    name: "Villa Riachuelo",
    comuna: 8,
    perimetro: 10596.8267392,
    area: 4232595.9247899996,
  },
  {
    name: "Villa Soldatti",
    comuna: 8,
    perimetro: 12441.695877300001,
    area: 8688163.5582800005,
  },
  {
    name: "Villa Pueyrredon",
    comuna: 12,
    perimetro: 7522.3603824299998,
    area: 3303239.50936,
  },
  {
    name: "Villa Devoto",
    comuna: 11,
    perimetro: 11562.0068,
    area: 6404480.8537100013,
  },
  {
    name: "Liniers",
    comuna: 9,
    perimetro: 9256.9273212900007,
    area: 4374418.7367700003,
  },
  {
    name: "Versalles",
    comuna: 10,
    perimetro: 5650.4662864299999,
    area: 1406833.5958,
  },
  {
    name: "Puerto Madero",
    comuna: 1,
    perimetro: 17725.720821200001,
    area: 5040970.8851399999,
  },
  {
    name: "Monserrat",
    comuna: 1,
    perimetro: 6739.2223110200002,
    area: 2198621.9408900002,
  },
  {
    name: "San Nicolas",
    comuna: 1,
    perimetro: 6548.0847539599999,
    area: 2289007.5825899998,
  },
  {
    name: "Belgrano",
    comuna: 13,
    perimetro: 20609.7753969,
    area: 7999239.81384,
  },
  {
    name: "Recoleta",
    comuna: 2,
    perimetro: 21452.838647799999,
    area: 6317264.5605600001,
  },
  {
    name: "Retiro",
    comuna: 1,
    perimetro: 18837.988687000001,
    area: 4512710.9070300004,
  },
  {
    name: "Nuñez",
    comuna: 13,
    perimetro: 10785.2610814,
    area: 4424199.2650899999,
  },
  {
    name: "Boca",
    comuna: 4,
    perimetro: 20060.003939400001,
    area: 5036240.4544099998,
  },
];

export const ContactModal = ({ show, hide, magazine, mail }) => {
  const contactTypes = [
    {
      label: "Consulta",
      value: "Consulta",
    },
    {
      label: "Solicitar Revista",
      value: "Petición Revista",
    },
  ];

  const [contactType, setContactType] = useState(magazine && contactTypes[1]);
  const [validateContactType, setValidateContactType] = useState(false);
  const user = useSelector((state) => state.user.user);
  const [email, setEmail] = useState(user?.email);
  const [phone, setPhone] = useState(user?.phoneNumber);
  const [social, setSocial] = useState();
  const [firstName, setFirstName] = useState(user?.firstName);
  const [lastName, setLastName] = useState(user?.lastName);
  const [message, setMessage] = useState("");
  const [sent, setSent] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [province, setProvince] = useState(user?.province);
  const [locality, setLocality] = useState(user?.locality);
  const [localities, setLocalities] = useState([]);
  const [provinces, setProvinces] = useState([]);

  useEffect(() => {
    setMessage("");
    setTimeout(() => {
      setSent(false);
    }, 1000);
  }, [show]);

  useEffect(() => {
    if (show && !provinces.length) {
      getProvinces();
    }
  }, [show, provinces]);

  useEffect(() => {
    if (show && province) {
      getLocalities();
    }
  }, [province]);

  const getLocalities = async () => {
    let localities = [];
    if (province.label.includes("Ciudad")) {
      localities = barrios;
    } else {
      let { data } = await ApiHelper.getLocalities(province.name);
      localities = data;
    }

    localities.map((localidad) => {
      localidad.label = localidad.name;
      localidad.value = localidad.name;
    });

    localities.sort(function (a, b) {
      let textA = a.label.toUpperCase();
      let textB = b.label.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    setLocalities(localities);
  };

  const getProvinces = async () => {
    try {
      let res = await ApiHelper.getProvinces();
      res.data.map((provincia) => {
        provincia.value = provincia.id;
        provincia.label = provincia.name;
      });
      res.data.sort(function (a, b) {
        let textA = a.label.toUpperCase();
        let textB = b.label.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
      setProvinces(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const submitForm = async (e) => {
    e.preventDefault();

    if (!contactType) {
      setValidateContactType(true);
      return;
    }

    const data = {
      email,
      subject: `${contactType.value} ${firstName} ${lastName}`,
      message: `
            ${contactType.value}: ${firstName} ${lastName}
            Email: ${email}
            Telefono: ${phone}
            Razon Social: ${social !== undefined ? social : "-"}
            Localidad: ${locality !== undefined ? locality.name : "-"}
            Provincia: ${province !== undefined ? province.name : "-"}
            Mensaje: ${message}`,
    };

    try {
      setIsFetching(true);
      if (mail === "infoauto@") {
        await ApiHelper.post("admin", "/mail/companies", data);
      } else {
        await ApiHelper.post("admin", "/mail", data);
      }

      setSent(true);
      setIsFetching(false);
      setTimeout(() => {
        hide();
      }, 2000);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      show={show}
      onHide={hide}
      size={sent ? "sm" : "lg"}
      className={"default_modal"}
    >
      <Modal.Body className={"p-0 background_gray"}>
        <div className={"text-right pt-3 pr-3 link"}>
          <FontAwesomeIcon onClick={hide} icon={faTimes} />
        </div>
        {sent ? (
          <div>
            <div className="row">
              <div className="col-12 gray_background p-5 text-center">
                <h4 className="font-weight-normal mb-0">
                  <strong>Mensaje enviado</strong>!
                </h4>
              </div>
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-12 gray_background p-5">
              <h1 className="font-weight-normal mb-0">
                Hola {user?.firstName && <strong>{user.firstName}</strong>}!
              </h1>
              <h1 className="font-weight-normal mb-0 border-bottom-1 pb-3">
                Queremos escucharte
              </h1>
              <form id="contact-form" onSubmit={submitForm}>
                <div className="row mt-3">
                  <div className="mt-2 col-12  ">
                    <label className="font-weight-bold">
                      Seleccionar tipo de consulta
                    </label>{" "}
                    <Select
                      required
                      className={"w-100"}
                      placeholder={"Seleccioná"}
                      onSelect={(type) => setContactType(type)}
                      selectedOption={contactType}
                      options={contactTypes}
                    />
                  </div>
                </div>

                {contactType === null && validateContactType && (
                  <div className="d-flex mt-2  align-items-center">
                    <p style={{ color: "red" }}>
                      Seleccione un tipo de contacto.
                    </p>
                  </div>
                )}
                <div className="row mt-3">
                  <div className="mt-2 col-12 col-md-6 ">
                    <label className="font-weight-bold">Nombre</label>
                    <input
                      required
                      name={"firstName"}
                      defaultValue={firstName}
                      onChange={(event) => setFirstName(event.target.value)}
                      className={"custom_input default-shadow p-2 w-100 mb-3"}
                      placeholder={"Nombre"}
                      type={"text"}
                    />
                  </div>
                  <div className="mt-2 col-12 col-md-6 ">
                    <label className="font-weight-bold">Apellido</label>
                    <input
                      required
                      name={"lastName"}
                      defaultValue={lastName}
                      onChange={(event) => setLastName(event.target.value)}
                      className={"custom_input default-shadow p-2 w-100 mb-3"}
                      placeholder={"Apellido"}
                      type={"text"}
                    />
                  </div>
                  <div className="mt-2 col-12 col-md-6 ">
                    <label className="font-weight-bold">Email</label>
                    <input
                      required
                      name={"email"}
                      defaultValue={email}
                      onChange={(event) => setEmail(event.target.value)}
                      className={"custom_input default-shadow p-2 w-100 mb-3"}
                      placeholder={"Email"}
                      type={"email"}
                    />
                  </div>
                  <div className="mt-2 col-12 col-md-6 ">
                    <label className="font-weight-bold">Telefono</label>
                    <input
                      name={"phone"}
                      defaultValue={phone}
                      onChange={(event) => setPhone(event.target.value)}
                      className={"custom_input default-shadow p-2 w-100 mb-3"}
                      placeholder={"Teléfono"}
                      type={"tel"}
                    />
                  </div>
                  {contactType?.value === "Petición Revista" && (
                    <>
                      <div className="mt-2 col-12 col-md-6 ">
                        <label className="font-weight-bold">Provincia</label>
                        <Select
                          required
                          className={"w-100"}
                          placeholder={"Seleccioná"}
                          onSelect={(e) => setProvince(e)}
                          selectedOption={province}
                          options={provinces}
                        />
                      </div>

                      <div className="mt-2 col-12 col-md-6 ">
                        <label className="font-weight-bold">Localidad</label>
                        <Select
                          required
                          className={"w-100"}
                          placeholder={"Seleccioná"}
                          onSelect={(locality) => setLocality(locality)}
                          selectedOption={locality}
                          options={localities}
                        />
                      </div>
                    </>
                  )}

                  {user?.type === "professional" && (
                    <div className="mt-2 col-12 col-md-6 ">
                      <label className="font-weight-bold">Razón Social</label>
                      <input
                        name={"social"}
                        defaultValue={social}
                        onChange={(event) => setSocial(event.target.value)}
                        className={"custom_input default-shadow p-2 w-100 mb-3"}
                        placeholder={"Razón Social"}
                        type={"text"}
                      />
                    </div>
                  )}

                  <div className="col-12 mb-4 d-flex flex-column">
                    <label className={"font-weight-bold"}>Mensaje</label>
                    <textarea
                      required
                      defaultValue={message}
                      onChange={(event) => setMessage(event.target.value)}
                      placeholder={"Escriba su mensaje"}
                      className={"custom_input text_area w-100 p-2 px-3"}
                    />
                  </div>

                  <div className="col-12 text-center mt-3 text-center text-md-right">
                    <SquareButton
                      loading={isFetching}
                      onClick={submitForm}
                      text={"ENVIAR"}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};
