import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import actions from "../../../store/actions";
import ContactModal from "../../enterprise/ContactModal";

const Subscription = ({ subscription, isProfessional }) => {
  const history = useHistory();
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setShowModal(showModal);
  }, [showModal]);

  const optionsProfessional = [
    "PRODUCTORES",
    "PERITOS",
    "CONCESIONARIAS",
    "TASADORES",
    "AGENCIAS MULTIMARCA",
  ];

  return (
    <>
      <div className="mt-4 mx-2">
        <div className="card">
          <div className="embed-responsive embed-responsive-1by1">
            <img
              className="card-img-top embed-responsive-item"
              src={subscription.image}
              alt="Subscription"
            />
            <div className="embed-responsive-item position-absolute subscription_card_bullets py-4 d-flex flex-column justify-content-between">
              <ul className="mb-0">
                {subscription.bullets.map((bullet, index) => (
                  <li key={index} className="mb-2 text_small">
                    {bullet}
                  </li>
                ))}
              </ul>
              {isProfessional && (
                <>
                  <hr color="white" className="mx-4 mt-0 mb-0" />
                  <div className="d-flex mb-0 flex-wrap justify-content-center">
                    {optionsProfessional.map((option) => (
                      <p className="text-white mb-1 text-professional-options ml-2 font-weight-normal">
                        {option}
                      </p>
                    ))}
                  </div>
                </>
              )}
              <div className="d-flex flex-column px-5 align-items-center">
                <button
                  onClick={() => history.push(subscription.link)}
                  type="button"
                  className="text_small btn btn-light btn-lg text-uppercase font-weight-bolder rounded-pill mb-2"
                >
                  Detalles
                </button>

                {(!user ||
                  (user?.type === "professional" &&
                    subscription.name === "Profesionales" &&
                    !user.activeSuscription) ||
                  (user?.type === "individual" &&
                    subscription.name === "Particulares" &&
                    !user.activeSubscription)) && (
                  <button
                    onClick={() =>
                      subscription.name !== "Empresas"
                        ? user
                          ? (window.location.href = `${window.location.origin}/perfil/plan`)
                          : dispatch(actions.app.setShowLogin(true))
                        : setShowModal(true)
                    }
                    type="button"
                    className="btn btn-dark btn-lg text-uppercase font-weight-bolder rounded-pill text_small"
                  >
                    {subscription.name === "Empresas"
                      ? "Contacto"
                      : "Suscribirse"}
                  </button>
                )}
              </div>
            </div>
          </div>
          <div
            className={`card-body ${
              subscription.name === "Empresas" ? "bg-dark" : null
            }`}
          >
            <h5 className="card-title font-weight-normal text-uppercase mb-0">
              {subscription.name}
            </h5>
          </div>
        </div>
      </div>
      <ContactModal
        show={showModal}
        hide={() => setShowModal(false)}
        mail="infoauto@"
      />
    </>
  );
};

export default Subscription;
